.accordion{
    width: 100%;
    margin: 0;
    padding: 0;
    border: 1px solid #ccc;
    border-radius: 5px;
}
.accordion__head{
    display: block;
    padding: 15px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
}
.accordion__head img{
    display: inline-block;
    fill: red;
}
h3.accordion__head{
    font-weight: lighter;
    font-size: 1.2em;
}
.accordion__head.active{
    background: rgb(255, 240, 240);
}
.accordion__head:hover{
    background: #fff2f2;
}
.accordion__body{
    display: none;
    margin: 0px;
    padding: 20px 15px 15px 15px;
}
.accordion__body.active{
    display: block;
    animation-name: slideIn;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}
.accordion__body.inactive{
    display: none;
}

@keyframes slideIn {
    0% {
        opacity: 0;
        margin-top: -30px;
    }
    20%{
        opacity: 1;
    }
    100% {
        opacity: 1;
        margin-top: -15px;
    }
}
