#icon-preview{
    margin-bottom: 10px;
}
#icon-preview img{
    max-width: 256px;
    max-height: 256px;
}

#cover-preview{
    margin-bottom: 10px;
}
#cover-preview img{
    max-width: 512px;
    max-height: 512px;
}