.alert{
    padding: 10px;
    margin: 10px 0px;
    border-radius: 5px;
    color: #000;
}
.alert-primary{
    background-color: #CDE5FF;
    border: #B9DAFF 1px solid;
}
.alert-success{
    background-color: #D5EDDA;
    border: #B9DAFF 1px solid;
}
.alert-danger{
    background-color: #F9D7DA;
    border: #F6C6CB 1px solid;
}
.alert-warning{
    background-color: #FFF3CD;
    border: #FFE0B2 1px solid;
}
.alert-info{
    background-color: #D1ECF1;
    border: #B9DAFF 1px solid;
}
.alert .close{
    float: right;
}