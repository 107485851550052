/*------------------------------------------------------------*/
/* レイアウト                                                 */
/*------------------------------------------------------------*/
.text-center{
    text-align: center;
}
.box-center{
    margin: 0 auto;
}
p{
    line-height: 160%;
}

/*------------------------------------------------------------*/
/* 背景                                                       */
/*------------------------------------------------------------*/
.bg{
    width: 100%;
    padding: 10px 20px;
    margin: 0px 10px;
}
.bg-section{
    width: 100%;
    padding: 20px;
    margin: 20px 0px;
    background-color: #fff;
    border-radius: 15px;
}

/*------------------------------------------------------------*/
/* Typography                                                 */
/*------------------------------------------------------------*/
/*----------------------------*/
/* 見出し                     */
/*----------------------------*/
h1 {
    text-align: left;
    font-size: 32px;
    color: #000;
    margin-bottom: 10px
}
h2{
    font-size: 24px;
    color: #000;
    margin-bottom: 10px
}
h3{
    font-size: 20px;
    color: #000;
    margin-bottom: 10px
}

/*----------------------------*/
/* 文字装飾                   */
/*----------------------------*/
.text-white{ color: #000;}
.text-red{ color: rgb(255, 65, 65) }
.text-blue{ color: rgb(0, 130, 255) }
.text-green{ color: rgb(30, 205, 40) }
.text-bold{ font-weight: bold }
.text-underline{ text-decoration: underline }
.text-muted { color: #999 }
.text-small{ font-size: 0.75em }

.text-marker-red{
    background-color: rgb(255, 65, 65);
    color: #fff;
    padding: 0px 5px;
    border-radius: 5px;
}
.text-marker-green{
    background-color: rgb(30, 205, 40);
    color: #fff;
    padding: 0px 5px;
    border-radius: 5px;
}
.text-mega{ font-size: 2.0em }
.text-big{ font-size: 1.5em }
.text-normal{ font-size: 1em }
.text-small{ font-size: 0.75em }

/* フォーム下の説明文 */
.help-block{
    display: block;
    color: gray;
    font-size: 1em;
    margin: 3px 5px;
    padding: 0px;
}

/* リンク */
a.normal{
    color: blue;
    text-decoration: underline;
}

/*----------------------------*/
/* リスト                     */
/*----------------------------*/
ul.list-normal{
    list-style-type: square;
    padding: 0;
    margin: 10px 30px;
}
ul.list-normal li{
    margin-bottom: 3px;
}
ol.list-normal{
    list-style-type:decimal;
    padding: 0;
    margin: 10px 30px;
}
ol.list-normal li{
    margin-bottom: 3px;
}

/*----------------------------*/
/* 水平線                     */
/*----------------------------*/
hr{
    margin: 20px 0px;
}

/*------------------------------------------------------------*/
/* フォーム部品                                               */
/*------------------------------------------------------------*/
.form-section{
    width: 100%;
    margin: 30px 0px;
}

/*----------------------------*/
/* ラベル                     */
/*----------------------------*/
label.form-label {
    display: block;
    font-weight: bold;
    font-size: 1.1em;
    margin-bottom: 5px;
}

/* チェックボックスの各要素 */
label.form-check-label{
    display: block;
    margin-top: 10px;
}

/* ラジオボタンの各要素 */
label.form-radio-label{
    display: block;
    margin-top: 10px;
}

/*----------------------------*/
/* テキストボックス/エリア    */
/*----------------------------*/
.form-control, .form-select{
    font-family: monospace;
    font-size: 1.1em;
    letter-spacing: 0.15em;
    padding: 8px 40px 8px 8px;  /* 上 | 右 | 下 | 左 */
}
input[type="text"].form-control,input[type="number"].form-control,input[type="date"].form-control{
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin: 2px 0px;
}
input[type="text"]:disabled.form-control,input[type="number"]:disabled.form-control,input[type="date"]:disabled.form-control, textarea:disabled.form-control{
    background-color: rgb(235, 235, 235);
    cursor: not-allowed;
}

/* スピンボタンを非表示 */
input[type="number"].no-spin::-webkit-outer-spin-button,
input[type="number"].no-spin::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield;
}

textarea.form-control{
    width: 100%;
    height: 10em;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin: 2px 0px;
    font-family: monospace;
}

input[type="text"].size-1-3{
    width: 30%;
    margin-right: 20px;
}

/*----------------------------*/
/* ボタン                     */
/*----------------------------*/
/* ベース */
button.btn{
    min-width: 8em;
    border-radius: 5px;
    padding: 10px;
}
button.btn:disabled{
    background-color: rgb(160, 160, 160);
    cursor: not-allowed;
}
button.btn:disabled:hover{
    background-color: rgb(170, 170, 170);
    cursor: not-allowed;
}
/* primary */
button.btn-primary{
    background-color: #DB3197;
    font-size: 18px;
    color: #fff;
    padding: 20px 20px
}
button:hover.btn-primary{
    background-color: #E979BC;
    color: #fff;
}

button.btn-normal{
    background-color: #fff;
    font-family: source-han-sans-japanese,noto-sans-cjk-jp,Hiragino Kaku Gothic ProN,Hiragino Sans,Meiryo,sans-serif;
    font-size: 14px;
    color: gray;
    padding: 10px 10px
}

/*----------------------------*/
/* プルダウン                 */
/*----------------------------*/
select.form-select{
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin: 2px 0px;
}

/*----------------------------*/
/* チェックボックス           */
/*----------------------------*/
input[type="checkbox"].form-check{
    width: 1.5em;
    height: 1.5em;
    margin-right: 3px;
}

/*----------------------------*/
/* ラジオボタン               */
/*----------------------------*/
input[type="radio"].form-radio{
    width: 1.5em;
    height: 1.5em;
    margin-right: 3px;
}

/*----------------------------*/
/* validaiton                 */
/*----------------------------*/
.form-valid{
    outline: none;
    box-shadow:
    2px 2px #198754,
    -2px 2px #198754,
    -2px -2px #198754,
    2px -2px #198754,
    2px 0px #198754,
    0px 2px #198754,
    -2px 0px #198754,
    0px -2px #198754;
    border-color: #198754;

    background-image: url("data:image/svg+xml,<svg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='check' class='svg-inline--fa fa-check fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path fill='green' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'></path></svg>");
    background-repeat: no-repeat;
    background-position: right 5px center;
    background-size: 2.0em;
}

.form-invalid{
    outline: none;
    box-shadow:
    2px 2px lightcoral,
    -2px 2px lightcoral,
    -2px -2px lightcoral,
    2px -2px lightcoral,
    2px 0px lightcoral,
    0px 2px lightcoral,
    -2px 0px lightcoral,
    0px -2px lightcoral;
    border-color: transparent;
    background-color: rgb(255, 250, 250);

    background-image: url("data:image/svg+xml,<svg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='exclamation-circle' class='svg-inline--fa fa-exclamation-circle fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path fill='lightcoral' d='M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'></path></svg>");
    background-repeat: no-repeat;
    background-position: right 5px center;
    background-size: 2.0em;
}

.valid-feedback{
    display: none;
    color: green;
    background-color: rgb(230, 245, 230);
    border-radius: 5px;
    padding: 5px;
    margin-top: 5px;
}
.invalid-feedback{
    display: none;
    color: red;
    background-color: rgb(255, 232, 235);
    border-radius: 5px;
    padding: 5px;
    margin-top: 5px;
}

.request-progress{
    margin: 10px 0px;
    padding: 10px;
    border-radius: 20px;
    color: gray;
    background-color: aliceblue;
}

/*------------------------------------------------------------*/
/* アバター                                                   */
/*------------------------------------------------------------*/
.icon-creator{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

