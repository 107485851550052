#games-add-progress{
    display: none;
}
#games-add-progress h2{
    font-size: 1.8em;
}
#progress{
    font-size: 1.5em;
    margin-top: 1.2em;
}
#progress-error{
    width: 100%;
    height: 130px;
    margin: 20px 0px;
    border-radius: 5px;
}
#progress-error h3{
    font-size: 1.5em;
    margin-bottom: 10px;
}
#progress .progress-todo{
    color: gray;
}
#progress .progress-do{
    font-weight: bold;
}
#progress .progress-done{
    color: gray;
}
#progress-error form{
    margin-top: 20px;
}
