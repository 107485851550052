.card{
    border: 1px solid lightgray;
    border-radius: 5px;
    width: calc(100% / 3 - 20px);
    min-height: 200px;
    margin: 15px 10px;
    padding: 10px;
}
.card:hover{
    opacity: 0.8;
}

.card-image{
    background-color: #f5f5f5;
    width: 100%;
    min-height: 160px;
    cursor: pointer;
}

.card-title{
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
}
.card-text{
    font-size: 14px;
    text-align: center;
    margin-top: 10px;
}